import './App.css';
import React, { Component } from 'react';

class App extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {
      showingterms: false,
      page: "Documents"
    }
  }

  navbar() {
    return (
      <div className="Card-Navbar">
        <u onClick = {() => this.setState({page: "Documents"})}>Documents</u> •&nbsp;
        <u onClick = {() => this.setState({page: "Forms"})}>Forms</u> •&nbsp;
        <u onClick = {() => this.setState({page: "Research"})}>Research Links</u>
      </div>
    )
  }

  currentpage() {
    switch(this.state.page)
    {
      case "Documents":
        return Documents();

      case "Research":
        return Research();

      case "Forms":
        return Forms();
    }
  }

  //Don't forget to change cursor to pointer

  render() {
    return (
      <div className="Container">
        <div className="Title"><img className="s-logo" src="whiteoutline.png" /><div className="text-logo">IFACT</div></div>
        <div className="Subtext">A collection of all of my links</div>
        <div className="Card">
            {this.state.showingterms ? this.navbar() : this.navbar()}
            {this.state.showingterms ? this.currentpage() : this.currentpage()}
          <div className="Card-Footer">If you're here by accident go back <a href="https://sifact.com.au">here.</a></div>
        </div>
      </div>
    );
  }
}

function Documents() {
  return (
    <>
      <div className="Card-Header">Documents</div>
      <div className="Card-Body">
        <div>
          <a href="https://fsg.sifact.com.au/flyer.png">FSG Flyer</a> <br />
          <a href="https://docs.google.com/document/d/1HsCbKRmmrVG9XMf6L61Xwxef6CnPllqb1o4Wsis_cW0/edit?usp=sharing">Terms and Conditions</a>
          <hr />
          <a href="https://docs.google.com/spreadsheets/d/14W8Ny1foDk4Zc--aaCdS2YEjzrYUbQ7L1QXf7J63LSw/edit?usp=sharing">Invoice Template</a> <br />
          <a href="https://docs.google.com/spreadsheets/d/1Thfx7ybLPr27ew8Js-RkLs10LHbcaIPXd7Ano5CJf9k/edit?usp=sharing">Receipt Template</a>
          
        </div>
      </div>
    </>
  )
}

function Research() {
  return (
    <>
      <div className="Card-Header">Research Links</div>
      <div className="Card-Body">
        <div>
          <a href="https://drive.google.com/file/d/10Edx-8sb0e0223Z6z7Ii1uQS5cz7HYaz/view">Cognitive Neuroscience Textbook</a>
          <hr />
          <a href="https://researchrabbitapp.com/">Research Rabbit</a> <br />
          <a href="https://www.therapistaid.com/">Therapist Aid</a> <br />
          <a href="https://www.ncbi.nlm.nih.gov/">NCBI (Pubmed + Genes + Clinical Trials + Books etc.)</a> <br />
          <a href="https://pubmed.ncbi.nlm.nih.gov/">Just Pubmed (Research Papers)</a>
        </div>
      </div>
    </>
  )
}

function Forms() {
  return (
    <>
      <div className="Card-Header">Forms</div>
      <div className="Card-Body">
        <div>
          <a href="https://docs.google.com/forms/d/1ZyMLEn2cIMBrdnGKk2FGR21KM3WzBK1aYxMr6pVgTHU/edit#responses">SIFACT Registration Form</a> <br />
          <a href="https://docs.google.com/forms/d/120YVCUo-O8zbr5iQs9bWmlakkS5bdvd0NdNMwUVaqeg/edit#responses">FSG Registration Form</a>
          <hr />
          <a href="https://docs.google.com/forms/d/1lII7i6zk_AeeOQtBAi1WarwGQ5SqwayR9rZrPTgttEk/edit#responses">6 Question Wellbeing Form</a> <br />
          <a href="https://docs.google.com/forms/d/1xfzRNjjIS0gV7y2j_CGi9NAbi3NBy0dKMLWqh23Od3E/edit#responses">F+C Therapist EVF</a> <br />
          <a href="https://docs.google.com/forms/d/11R2_201XGzOQjHcsOMe7LawOOEAiOsxnV2kEs-kCtL4/edit#responses">Individual Therapist EVF</a> <br />
          <a href="https://docs.google.com/forms/d/1VladxMDVGiHo7CwXz8lpF4dVzBMha9eo8VFutUtplP8/edit#responses">FSG Evaluation Form</a> <br />
          <a href="https://docs.google.com/forms/d/1kg2fDnyln6dTlWExohaHmbzrPZeeL8Cd33mpWasWuWk/edit#responses">FSG Guest Speakers Evaluation Form</a>
        </div>
      </div>
    </>
  )
}

export default App;